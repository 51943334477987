<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div class="level-item">
                <h1 class="title is-1 page-title">
                    Travel Insurance Help, Advice and Buying Guides
                </h1>
            </div>
            <div class="box b-shdw-3 mt-5">
                <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
                <p class="paragraph-text">
                    If you’re struggling to find the right policy for your trip, or you want to know more about the different types of travel insurance available, we can help.
                </p>
                <p class="paragraph-text">
                    We’ve put together a number of different articles with helpful tips and information to assist you in finding the right policy for your holiday.
                </p>
                <p class="paragraph-text">
                    Take a look below and get in touch if you have any questions:
                </p>  
                <p class="blue-text">Why choose Covered2go for your travel insurance?</p>
                <p class="paragraph-text">
                   Here at Covered2go we’re a family run business with over 100 years of combined experience in the insurance industry. We specialise in travel insurance and aim to deliver great cover at affordable prices. We offer a wide range of travel insurance policies and cover levels, to suit many different types of trips, including Single Trip, Annual Multi-Trip, Winter Sports, Cruise plus Longstay. 
                </p>
                <p class="paragraph-text">
                    Our experience has taught us what really matters to consumers, and how we can really help when they encounter difficulties before or during their holiday. 
                </p>
                <p class="paragraph-text">
                    Our online quote system makes it easy to compare our policies, and our friendly team are always on hand to answer any questions you might have.
                </p>
                <p class="blue-text">Looking for a travel insurance quote?</p>
                <p class="paragraph-text">
                    <a href="get-quote" target="_blank" class="text-link">Get a quote now!</a> Just fill in your details and you’ll instantly be able to compare policies that will meet your needs.
                </p>
                <p class="paragraph-text">
                    <a href="do-i-need-travel-insurance" target="_blank" class="text-link">Do I really need travel insurance?</a>
                </p>                    
                <p class="paragraph-text">
                    If you’re wondering whether you really need travel insurance, take a look at our handy buying guide. We’ve explained how travel insurance works, exactly what is covered and the kind of issues that you could encounter on holiday that makes it really worthwhile.
                </p>
                <p class="paragraph-text">
                    We also explain the different types of travel insurance, and what is generally covered under a policy.
                </p>
                <p class="paragraph-text">
                    <a href="family-travel-insurance" target="_blank" class="text-link">Family Travel Insurance</a>
                </p>  
                <p class="paragraph-text">
                    If you’re travelling with your children, then you might be wondering what kind of policy you should buy. We’ve put together a buying guide to help you choose the right travel insurance for your family.
                </p>
                <p class="paragraph-text">
                    <a href="group-travel-insurance" target="_blank" class="text-link">Travelling in a Group</a>
                </p>  
                <p class="paragraph-text">
                    If you’re travelling in a group, there can be a number of advantages to buying a group travel insurance policy. However, it’s not right for everyone, so we’ve put together a few pros and cons to help you make your decision.
                </p>
                <p class="paragraph-text">
                    <a href="over-65s-travel-insurance" target="_blank" class="text-link">Travellers Over 65</a>
                </p> 
                <p class="paragraph-text">
                    Once you turn 65, you might notice that travel insurance is a little more expensive than it used to be. We’ve together an article explaining why and how policies for over 65s can offer a wealth of different additional benefits.
                </p>
                <p class="paragraph-text">
                    <a href="holiday-checklist" target="_blank" class="text-link">Holiday Checklist</a>
                </p> 
                <p class="paragraph-text">
                    Whether you’re packing weeks in advance, or racing around at the last minute, use our holiday checklist so you don’t forget any of the essentials. We’ve broken it down in to logical sections, to make a big job seem a little bit smaller!
                </p>
                <p class="paragraph-text">
                    <a href="travel-by-destination" target="_blank" class="text-link">Travel by Destination </a>
                </p> 
                <p class="paragraph-text">
                    Whether you’re planning a backpacking trip around Europe or an adventure to the USA, you need to make sure you have the right policy in place.
                </p>
                <p class="paragraph-text">
                    Knowing your travel insurance options is important. We’ve looked at a number of different locations and explained exactly how travel insurance can help when travelling to that destination.
                </p>
                <p class="paragraph-text">
                    <a href="/blog/ghic-information-everything-you-need-to-know" target="_blank" class="text-link">EHIC / GHIC Information  </a>
                </p> 
                <p class="paragraph-text">
                    The European Health Insurance Card, or EHIC for short, is being phased out and replaced by the Global Health Insurance Card. 
                </p>
                <p class="paragraph-text">
                    If you’re travelling to Europe soon, it is important that you have your EHIC or GHIC in place, as well as your travel insurance. We explain why it’s best to travel with both in our helpful guide, with information all about the EHIC / GHIC. 
                </p>

                <p class="paragraph-text">
                    <a href="cruise-travel-insurance" target="_blank" class="text-link">Cruise Travel Insurance</a>
                </p> 
                <p class="paragraph-text">
                    Cruise travel insurance is a policy tailored to cover mishaps which might happen while you are on a cruise - such as transport to a hospital if you take ill while at sea or missed port stops.
                </p>
                <p class="paragraph-text">
                    Our guide to cruise travel insurance should help to answer any questions you might have.
                </p>
                <p class="paragraph-text">
                    <a href="winter-sports-travel-insurance" target="_blank" class="text-link">Winter Sports Travel Insurance</a>
                </p> 
                <p class="paragraph-text">
                    Winter sports travel insurance is designed specifically to cover you when you take part in winter sports activities while on holiday – things like skiing, snowboard and sledding.
                </p>
                <p class="paragraph-text">
                    Here’s what you need to know in our guide to winter sports travel insurance. 
                </p>
                <p class="paragraph-text">
                    <a href="business-travel-insurance" target="_blank" class="text-link">Business Travel Insurance</a>
                </p>
                <p class="paragraph-text">
                    Business travel insurance is a type of cover which will make sure you are covered during your business trip for business effects, laptops, money, plus accidents, illness, flight cancellations and more.
                </p>
                <p class="paragraph-text">
                    <a href="faqs" target="_blank" class="text-link">Travel Insurance FAQs</a>
                </p> 
                <p class="paragraph-text">
                    Find the answers to some frequently asked questions about Covered2go travel insurance policies, optional extras, how to claim on your policy and more. If you need some help, please don’t hesitate to <a href="contact-us" target="_blank" class="text-link">contact us</a>. 
                </p>
                <p class="blue-text">Looking for a travel insurance quote?</p>
                <p class="paragraph-text">
                    When you do decide to book your next holiday, don’t forget you can <a href="get-quote" target="_blank" class="text-link">get a new travel insurance quote</a> in minutes.
                </p>
                <p class="paragraph-text">
                    Book with confidence knowing your holiday is protected with Covered2go and if we can be of any assistance, then please do not hesitate to get in touch.
                </p>

                <br />

                <InfoButtonOptions :policyType="'ST'" :coverType="'ST'" />
                <info-boxes />
                <!-- <trustpilot-banner /> -->


            </div>
        </div>
    </transition>
</template>
<script>
    import InfoBoxes from '../components/common/InfoBoxes';
    // import TrustpilotBanner from '@/components/common/trustpilot/TrustpilotBanner';
    import InfoButtonOptions from '@/components/common/InfoButtonOptions';
    export default {name: "TravelByDestination",data() {return {}},methods: {},components: {
        InfoBoxes,
        // TrustpilotBanner,
        InfoButtonOptions,
    },created() {}}
</script>

<style lang="scss" scoped>
    .main-page-title {color: $c2g_orange; font-size:130%; text-decoration:none; text-align:left; padding-bottom:15px; font-weight: bold;}
    .paragraph-text {color:black; font-size:100%; padding-bottom:15px;}
    .main-list-class{padding-left:25px; padding-bottom:15px;}
    .list-item{list-style-type: disc; color:black;}
    .blue-text {margin-top: 10px; text-align:left; color: $c2g_blue; font-weight: bold; font-size:115%; padding-bottom:15px;}
    .bold-text{font-weight:bold;}
    .text-link{color:$c2g_orange; text-decoration: underline;}
    .extra-padding-bottom{padding-bottom:10px}
    .extra-padding-top{padding-top:10px}
    .underline{text-decoration:underline}
</style>